import React from 'react'
import { Box, Typography } from '@material-ui/core'

import { CallActionTypes } from '../../reducers/Call/CallActionsTypes'
import { useCallContext } from '../../reducers/Call/CallProvider'
// import { Timer } from '../../utils/Timer'
import { useTranslation } from 'react-i18next'
// import { CALL_TYPE } from '../../constants/defaultConstants'
// import { getCallReason, recognizeCallType } from './utils'

// import { ReasonChip } from './components'
// import { FlagChip } from './components/FlagChip'
// import moment from 'moment'
import { CallItem } from './components/CallItem'

export const RenderCalls = () => {
    // @ts-ignore
    const { callState, dispatchCall, setIsCalling, callSocket, setIsInStream } =
        useCallContext()

    const { t } = useTranslation()

    const { incomingCalls, selectedCall, othersCalls } = callState
    //console.log('SELECTED CALL------> ', selectedCall)
    const handleSetCall = (el: any) => {
        const { call, type, user } = el

        const reservationObj = { call, type, user }
        callSocket.emit('call:reservation', reservationObj, (payload: any) => {
            console.log('reservation ', payload)

            setIsCalling(false)
            setIsInStream(true)
            dispatchCall({
                type: CallActionTypes.TAKE_CALL_FROM_INCOMINGS,
                payload: el
            })
        })
    }

    const isRenderSelected =
        !!selectedCall?.sessionName?.length &&
        selectedCall.status === 'answered'

    const calls = isRenderSelected
        ? [selectedCall, ...incomingCalls, ...othersCalls]
        : [...incomingCalls, ...othersCalls]
    return calls.length > 0 ? (
        calls.map((el) => {
            // const data = getMetaData(el.reason)
            // console.log('el', el)

            return (
                <CallItem
                    key={el.sessionName}
                    handleSetCall={handleSetCall}
                    isRenderSelected={isRenderSelected}
                    selectedCall={selectedCall}
                    sessionData={el}
                />
            )
            // return (
            //     <ButtonBase
            //         key={el.sessionName}
            //         style={{
            //             width: '100%',
            //             padding: '10px 0',
            //             borderBottom: '1px solid #ccc',
            //             backgroundColor:
            //                 el.sessionName === selectedCall.sessionName &&
            //                 el.status === 'answered'
            //                     ? '#008DFF30'
            //                     : el?.status === 'reserved'
            //                     ? '#00000030'
            //                     : isGroupCall
            //                     ? '#E7AC39'
            //                     : 'transparent'
            //         }}
            //         disabled={
            //             isRenderSelected ||
            //             el?.status === 'reserved' ||
            //             selectedCall.type === CALL_TYPE.OUTGOING_CALL
            //         }
            //         onClick={handleSetCall.bind(null, el)}
            //     >
            //         <Box
            //             style={{
            //                 width: 100,
            //                 marginRight: 20,
            //                 marginLeft: 10,
            //                 height: 'auto',
            //                 display: 'flex',
            //                 gap: 10,
            //                 flexDirection: 'column',
            //                 position: 'relative',
            //                 alignItems: 'center',
            //                 justifyContent: 'center'
            //             }}
            //         >
            //             <img src={recognizeCallType(el.type)} alt={''} />
            //             {icon && text && <ReasonChip icon={icon} text={text} />}
            //             {el.lang ? (
            //                 <FlagChip language={el.lang} user={el.user} />
            //             ) : null}
            //         </Box>

            //         <Box
            //             style={{
            //                 padding: 0,
            //                 width: '100%'
            //             }}
            //         >
            //             <Typography variant="body1">
            //                 {el.user?.employee?.department?.name}
            //             </Typography>
            //             <Typography variant="body1">
            //                 {el.user?.profile?.last_name}{' '}
            //                 {el.user?.profile?.first_name}{' '}
            //                 {el.user?.profile?.patronymic}
            //             </Typography>
            //             <Typography variant="body1">
            //                 {el?.user?.login ?? el.user?.properties?.name}
            //             </Typography>
            //             {text && (
            //                 <Typography variant="subtitle1">
            //                     {t(text)}
            //                 </Typography>
            //             )}
            //             <Typography variant="body1">
            //                 {callDate} {' / '}
            //                 <Timer start={new Date(el.startedAt)} />
            //             </Typography>
            //         </Box>
            //     </ButtonBase>
            // )
        })
    ) : (
        <Box
            style={{
                display: 'flex',
                justifyItems: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%'
            }}
        >
            <Typography variant="subtitle1">{t('callsHere')}</Typography>
        </Box>
    )
}
