import React from 'react'
import { Box, ButtonBase, Typography } from '@material-ui/core'
// import VideoCallIcon from '../../assets/videoCallIcon.svg'
// import FireIcon from '../../assets/fireIcon.svg'
// import EmergencyIcon from '../../assets/ambulanceIcon.svg'
// import GasIcon from '../../assets/gasIcon.svg'
// import PoliceIcon from '../../assets/policeIcon.svg'
// import CallRequestIcon from '../../assets/callOutIcon.svg'
import { useCallContext } from '../../reducers/Call/CallProvider'
import moment from 'moment'
import { CALL_TYPE } from '../../constants/defaultConstants'
import { ReasonChip } from './components'
import { getCallReason, recognizeCallType } from './utils'
import { FlagChip } from './components/FlagChip'

export const CallHistoryItems = () => {
    const { callState, callSocket, makeOutGoingCall } = useCallContext()
    const { historyCalls, selectedCall } = callState

    return historyCalls?.map((el) => {
        const callDate = moment(new Date(el.createdAt)).format(
            'DD.MM.YYYY / HH:mm'
        )

        const callLength = `/ ${moment(
            new Date(el.endedAt) - new Date(el.initiatedAt)
        )
            .utc()
            .format('HH:mm:ss')}`

        let textColor = el.status === 'missed' ? '#F44336' : '#000'
        textColor = el.type === CALL_TYPE.OUTGOING_CALL ? '#528d0f' : textColor
        const caller = el.listeners.find((item) => item.type === 'caller')

        // const { icon, text } = getMetaData(el.reason)
        const { icon, text } = getCallReason(el.reason)
        const makeCall = () => {
            const { userId } = el.listeners.find(
                (listener) => listener.type === 'caller'
            )
            const createCallObj = {
                type: CALL_TYPE.OUTGOING_CALL,
                data: { clientId: userId }
            }

            callSocket.emit('new:call:create', createCallObj, (payload) => {
                console.log('new:call:create ', payload)
                makeOutGoingCall(payload)
            })
        }
        const isShouldRenderReasonChip = icon && text
        const isShouldRenderFlagChip = Boolean(el.language?.language) 

        return (
            <ButtonBase
                disabled={!!selectedCall.sessionName}
                onClick={makeCall}
                key={el.id}
                style={{
                    width: '100%',
                    padding: '10px',
                    gap: 8,
                    borderBottom: '1px solid #ccc',
                    backgroundColor:
                        el.sessionName === selectedCall.sessionName &&
                        el.status === 'answered'
                            ? '#008DFF30'
                            : el?.status === 'reserved'
                            ? '#00000030'
                            : el.isGroupCall // TODO : now it doesn't work
                            ? '#E7AC39'
                            : 'transparent'
                }}
            >
                <Box style={{ width: 80 }}>
                    <img
                        src={recognizeCallType(el.type)}
                        alt={''}
                        style={{ width: '50px' }}
                    />
                    {isShouldRenderReasonChip ? (
                        <ReasonChip icon={icon} text={text} />
                    ) : null}
                    {isShouldRenderFlagChip ? (
                        <FlagChip language={el.language.language} />
                    ) : null}
                </Box>

                <Box
                    style={{
                        padding: 0,
                        width: '100%'
                    }}
                >
                    <Typography variant="body1" style={{ color: textColor }}>
                        {caller?.properties?.name}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        style={{ color: textColor }}
                    >
                        {callDate} {callLength}
                    </Typography>
                </Box>
            </ButtonBase>
        )
    })
}

// const getMetaData = (reason) => {
//     switch (reason) {
//         case 'emergency':
//             return {
//                 icon: EmergencyIcon,
//                 text: 'callTypes.103'
//             }
//         case 'fire department':
//             return {
//                 icon: FireIcon,
//                 text: 'callTypes.101'
//             }
//         case 'police':
//             return {
//                 icon: PoliceIcon,
//                 text: 'callTypes.102'
//             }
//         case 'gas service':
//             return {
//                 icon: GasIcon,
//                 text: 'callTypes.104'
//             }
//         case 'other':
//             return { icon: VideoCallIcon, text: 'callTypes.videoCall' }
//         case 'group':
//             return { icon: VideoCallIcon, text: 'callTypes.groupCall' }
//         case 'request':
//             return { icon: CallRequestIcon, text: 'callTypes.callRequest' }
//         default:
//             return { icon: VideoCallIcon, text: 'callTypes.videoCall' }
//     }
// }
