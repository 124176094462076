import React, { useState } from 'react'
import { Box, ButtonBase, alpha, Grid } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { CallService } from '../services/CallService'
import { useCallContext } from '../reducers/Call/CallProvider'
import { CallActionTypes } from '../reducers/Call/CallActionsTypes'
import { useTranslation } from 'react-i18next'

export const SearchInput = ({ handleClick }) => {
    const { callState, dispatchCall } = useCallContext()
    const classes = useStyles()
    const [value, setValue] = useState('')
    const { t } = useTranslation()

    return (
        <Box
            flexDirection="row"
            justify="center"
            justifyContent="center"
            alignItems="center"
            display="flex"
        >
            <Box
                style={{
                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                    borderRadius: 4,
                    width: 317,
                    marginTop: 18,
                    marginBottom: 19,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyItems: 'flex-start'
                }}
            >
                <ButtonBase
                    style={{ paddingLeft: 20 }}
                    onClick={() => handleClick(value)}
                >
                    <SearchIcon />
                </ButtonBase>
                <InputBase
                    onKeyPress={(e) => e.key === 'Enter' && handleClick(value)}
                    onChange={(e) => {
                        e.preventDefault()
                        setValue(e.target.value)
                    }}
                    placeholder={t('searchPlaceholder')}
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                />
            </Box>
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25)
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto'
        },
        backgroundColor: '#00000010'
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        // pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    inputRoot: {
        color: 'inherit'
        // width: '80%',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: '20px',
        transition: theme.transitions.create('width'),
        width: '100%'
    }
}))
