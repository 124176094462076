import React, { createContext, useReducer } from 'react'
import { INTERPRETER_STATE } from '../constants/defaultConstants'
import { AuthService } from '../services/AuthService'
import { InterpreterStatus } from 'src/types'
import { useCallContext } from './Call/CallProvider'

const initialState = {
    authenticated: () => {
        const user = AuthService.getUser()
        if (user == null) {
            return false
        }
        return true
    },
    error: null
}

const Reducer = (state, action) => {
    switch (action.type) {
        case INTERPRETER_STATE.SIGN_IN:
            if (action.payload.rememberMe) {
                AuthService.setUser(action.payload.user)
            }
            return {
                ...state,
                ...action.payload.user
            }
        case INTERPRETER_STATE.SIGN_OUT:
            AuthService.setUser(null)
            return { ...initialState }
        case INTERPRETER_STATE.AUTH_ERROR:
            AuthService.setUser(null)
            return { ...initialState }
        default:
            return state
    }
}

const AuthReducer = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState)
    const { activityChange } = useCallContext()

    React.useEffect(() => {
        const handleBeforeUnload = async (e) => {
            e.preventDefault()

            const confirmationMessage =
                'Are you sure you want to leave this page?'
			e.returnValue = confirmationMessage
			
            if (confirmationMessage) {
                try {
                    activityChange(InterpreterStatus.ON_BREAK)

                    setTimeout(async () => {
                        activityChange(InterpreterStatus.ONLINE)
                    }, 5000)
                } catch (error) {
                    console.error('Error handling beforeunload:', error)
                }
            }
        }

        window.addEventListener('beforeunload', handleBeforeUnload)

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [])

    return (
        <AuthContext.Provider value={[state, dispatch]}>
            {children}
        </AuthContext.Provider>
    )
}

export const AuthContext = createContext(initialState)
export default AuthReducer
