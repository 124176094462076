import PoliceIconSmall from 'src/assets/police-icon.svg'
import HeartIconSmall from 'src/assets/heart-icon.svg'
import GasIconSmall from 'src/assets/gas-icon.svg'
import LawIconSmall from 'src/assets/lawyer-icon.svg'
import DoctorIconSmall from 'src/assets/doctor-icon.svg'
import FireIconSmall from 'src/assets/fire-icon.svg'

export const getCallReason = (reason: string) => {
    switch (reason) {
        case 'emergency':
            return {
                icon: DoctorIconSmall,
                text: 'doctorCall'
            }
        case 'fire':
            return {
                icon: FireIconSmall,
                text: 'fireCall'
            }
        case 'police':
            return {
                icon: PoliceIconSmall,
                text: 'policeCall'
            }
        case 'gas':
            return {
                icon: GasIconSmall,
                text: 'gasCall'
            }
        case 'lawyer':
            return {
                icon: LawIconSmall,
                text: 'lawCall'
            }
        case 'psychologist':
            return {
                icon: HeartIconSmall,
                text: 'psychoCall'
            }
        default:
            return {
                icon: null,
                text: null
            }
    }
}
