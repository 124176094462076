export const startAudio = async (stream, audioEncode, audioDecode) => {
    const isSafari = window.safari !== undefined

    try {
        if (isSafari) {
            // desktop Safari, check if desktop Safari audio has been initialized
            if (audioEncode && audioDecode) {
                // desktop Safari audio has been initialized, continue to start audio
                await stream.startAudio()
            } else {
                // desktop Safari audio has not been initialized, retry or handle error
                console.log('safari audio has not finished initializing')
            }
        } else {
            // not desktop Safari, continue to start audio
            await stream.startAudio()
            console.log('Start audio')
        }
    } catch (error) {
        console.log('Error in start audio ', error)
    }
}
