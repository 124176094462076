import React from 'react'
import { Box, Typography } from '@material-ui/core'
import Lottie from 'react-lottie'

import AcceptCallIcon from '../../assets/voiceCallButtonIcon.svg'
import AcceptCallLottie from '../../assets/lottie/call-incoming.json'
import { useCallContext } from '../../reducers/Call/CallProvider'
import { Timer } from '../../utils/Timer'
import { fullHeight } from '../../utils/constants'
import { useTranslation } from 'react-i18next'

export const CallPreview = ({ startedAt, handleAccept }) => {
    const { t } = useTranslation()
    const { callState } = useCallContext()
    const { selectedCall } = callState

    const name =
        selectedCall.user?.employee?.department?.name ??
        selectedCall?.user?.login

    return (
        <Box
            style={{
                overflowY: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: fullHeight,
                alignItems: 'center',
                paddingTop: 100,
                paddingBottom: 100
            }}
        >
            <Typography variant="h4" style={{ color: '#000' }}>
                {t('call.abonentPay')}
            </Typography>
            <Typography variant="h4" style={{ color: '#000' }}>
                {name}
            </Typography>
            <Box alignItems="center">
                <Typography
                    align="center"
                    variant="subtitle1"
                    style={{ color: '#757575' }}
                >
                    {t('call.waiting')}
                </Typography>
                <Typography align="center" variant="h3">
                    <Timer start={startedAt} />
                </Typography>
            </Box>
            <Box
                style={{
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Box>
                    <Box
                        style={{
                            width: 180,
                            position: 'relative'
                        }}
                    >
                        <Lottie
                            options={{
                                animationData: AcceptCallLottie,
                                loop: true,
                                autoplay: true
                            }}
                        />
                        <img
                            onClick={handleAccept}
                            src={AcceptCallIcon}
                            alt=""
                            style={{
                                width: 120,
                                height: 120,
                                borderRadius: '50%',
                                alignSelf: 'center',
                                boxShadow:
                                    '0px 11px 15px rgba(0, 0, 0, 0.2), 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12)',
                                backgroundColor: '#4CAF50',
                                position: 'absolute',
                                left: '17%',
                                bottom: '19%',
                                cursor: 'pointer'
                            }}
                        />
                    </Box>
                    <Typography
                        align="center"
                        style={{ color: '#757575', marginTop: -20 }}
                    >
                        {t('call.connect')}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}
