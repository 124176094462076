import CallIcon from 'src/assets/call-icon.svg'
import QrCodeIcon from 'src/assets/qrCodeIcon.svg'
import CallOutIcon from 'src/assets/callOutIcon.svg'
// import CallOrderedIcon from 'src/assets/callOrderIcon.svg'
import WidgetIcon from 'src/assets/widget.svg'
import AmsaanLogo from 'src/assets/amsaan.svg'
import AmsaanTour from 'src/assets/amsaan-tour.svg'
import AmsaanPro from 'src/assets/amsaan-pro.svg'

import { AppType, CallType } from 'src/types'

export const recognizeCallType = ({
    app,
    type
}: {
    type: CallType
    app: string
}) => {
    const fromApp = {
        [AppType.AMSAAN]: AmsaanLogo,
        [AppType.AMSAAN_TOUR]: AmsaanTour
    }[app]

    const CallTypeDictionary = {
        [CallType.clientToOperator]: fromApp,
        [CallType.clientToOperatorCall]: AmsaanPro, //  TODO need remove this type
        [CallType.clientToGroupCall]: AmsaanPro,
        [CallType.clientToOperatorThroughLaptopQrCode]: QrCodeIcon,
        [CallType.employeeToOperator]: AmsaanLogo,
        [CallType.laptopToGroupCall]: AmsaanPro,
        [CallType.laptopToOperatorCall]: AmsaanPro,
        [CallType.operatorToClient]: CallOutIcon,
        [CallType.widgetToOperator]: WidgetIcon
    }

    const defaultValue = CallIcon

    return CallTypeDictionary[type] || defaultValue
}
