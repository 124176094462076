import React from 'react'
import * as ReactDOMClient from 'react-dom/client'
import ZoomVideo from '@zoom/videosdk';
import ZoomContext from './context/zoom-context';
import { App } from './App'
import './translations/helpers/translations'
import './index.css'

const zmClient = ZoomVideo.createClient();

const root = ReactDOMClient.createRoot(
    document.getElementById('root') as HTMLElement
)
root.render(
    //<React.StrictMode>
      <ZoomContext.Provider value={zmClient}>
        <App />
      </ZoomContext.Provider>
    //</React.StrictMode>
)

