import React, { useState, useContext, useEffect, useRef } from 'react'
import { Box, Button, Paper, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import ZoomContext from '../../context/zoom-context.ts'
import SendIcon from '../../assets/sendIcon.svg'
import { MessageItem } from './MessageItem'
import { fullHeight } from '../../utils/constants'

export const Chat = ({ closeTab, messages, setMessages }) => {
    const zmClient = useContext(ZoomContext)
    const [newMessage, setNewMessage] = useState('')
    const { t } = useTranslation()
    const chatClient = useRef(null)

    useEffect(() => {
        chatClient.current = zmClient.getChatClient()
    }, [zmClient])

    useEffect(() => {
        const history = chatClient.current.getHistory()
        const historyMessages = history.map((item) => ({
            from: item.sender.name,
            data: item.message
        }))
        historyMessages.reverse()
        setMessages(historyMessages)
    }, [setMessages])

    const sendMessageHandler = async () => {
        await chatClient.current.sendToAll(newMessage)
        setNewMessage('')
    }

    return (
        <Box
            style={{
                padding: 0,
                margin: 0,
                maxWidth: 370,
                minWidth: 370,
                backgroundColor: 'rgba(0,0,0,0)',
                height: fullHeight,
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: 'column',
                position: 'absolute',
                top: 40,
                right: 50,
                zIndex: 1000
            }}
        >
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column-reverse',
                    overflowY: 'scroll'
                }}
            >
                {messages?.map((message, index) => (
                    <MessageItem message={message} key={index} />
                ))}
            </Box>
            <Paper
                // elevation={3}
                style={{
                    width: '100%',
                    // minHeight: '80px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignSelf: 'flex-end',
                    alignItems: 'center',
                    paddingBottom: 10,
                    marginTop: 10,
                    boxShadow: '0px -4px 5px rgba(0, 0, 0, 0.14)'
                }}
            >
                <TextField
                    placeholder={t('chatPlaceholder')}
                    style={{
                        width: '100%',
                        alignSelf: 'center',
                        marginLeft: 10
                    }}
                    multiline={true}
                    maxRows={15}
                    value={newMessage}
                    onChange={(e) => {
                        e.preventDefault()
                        setNewMessage(e.target.value)
                    }}
                />

                <Button onClick={sendMessageHandler}>
                    <img src={SendIcon} alt="" />
                </Button>
                <Button onClick={closeTab}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                        width={35}
                        height={35}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </Button>
            </Paper>
        </Box>
    )
}
