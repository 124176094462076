import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { Container, Text } from './styles'
import { Divider, Typography, Grid } from '@material-ui/core'
import styles from './stylo.module.css'
import { ExitToApp, SettingsOutlined } from '@material-ui/icons'

import { AuthService } from '../../services/AuthService'
import { AuthContext } from '../../reducers/AuthReducer'
import { INTERPRETER_STATE } from '../../constants/defaultConstants'
import i18n from '../../translations/helpers/translations'
import { useTranslation } from 'react-i18next'
import { useCallContext } from '../../reducers/Call/CallProvider'
import { InterpreterStatus } from 'src/types'

const Titlebar = (props) => {
    const { t } = useTranslation()
    const { callState, activityChange } = useCallContext()

    const [authState, dispatch] = useContext(AuthContext)

    const statusOperatorHandler = (status) => {
        const toggleStatus =
            status === InterpreterStatus.ONLINE
                ? InterpreterStatus.ON_BREAK
                : InterpreterStatus.ONLINE
        activityChange(toggleStatus)
    }

    const isAuth = authState.authenticated()

    const HeaderTitle = () => (
        <div style={{ margin: '8px 0 8px 8px' }}>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_2072:14785)">
                    <path
                        d="M15.3291 0.666504C12.3043 0.666504 9.58271 2.33524 8.22739 5.02163C8.14845 5.17873 8.13516 5.35694 8.18988 5.52342C8.24459 5.68991 8.36105 5.82591 8.51815 5.90485C8.67526 5.98457 8.85346 5.99786 9.01995 5.94315C9.18721 5.88843 9.32243 5.77119 9.40137 5.61487C10.5324 3.374 12.8045 1.98195 15.3299 1.98195C19.0191 1.98195 22.0205 4.99349 22.0205 8.69519C22.0205 12.3727 19.0066 15.3647 15.301 15.3647C12.5739 15.3647 10.2346 13.8523 9.19581 11.4183C9.12703 11.2565 8.99962 11.1315 8.83627 11.0666C8.67291 11.0009 8.4947 11.0033 8.33291 11.0721C7.99995 11.2143 7.84441 11.6012 7.98588 11.935C9.23489 14.8613 12.037 16.6801 15.3002 16.6801C19.7303 16.6801 23.3343 13.0988 23.3343 8.69598C23.3343 4.26816 19.7428 0.666504 15.3291 0.666504Z"
                        fill="#00AAF6"
                    />
                    <path
                        d="M15.6694 17.7261C15.5076 17.6573 15.3286 17.6549 15.166 17.7206C15.0035 17.7863 14.8761 17.9113 14.8065 18.0723C13.7677 20.5063 11.4292 22.0187 8.70213 22.0187C7.38043 22.0187 6.10328 21.6404 5.00746 20.9252C4.84176 20.7775 4.61588 20.7235 4.40015 20.7814L2.74861 21.2238L3.191 19.5722C3.24493 19.3714 3.19882 19.1556 3.06751 18.9923C2.35781 17.9113 1.98342 16.6521 1.98342 15.35C1.98342 11.6483 4.9848 8.63596 8.67399 8.63596C11.1994 8.63596 13.4715 10.028 14.6025 12.2689C14.6814 12.426 14.8167 12.5424 14.9839 12.5972C15.1504 12.6519 15.3294 12.6386 15.4857 12.5596C15.6428 12.4807 15.7593 12.3455 15.814 12.1782C15.8687 12.0117 15.8554 11.8335 15.7765 11.6764C14.4204 8.99003 11.6988 7.32129 8.67477 7.32129C4.25946 7.32129 0.667969 10.9229 0.667969 15.3492C0.667969 16.8288 1.07441 18.2677 1.84429 19.5183L1.18383 21.9827C1.12287 22.2086 1.18774 22.4525 1.35422 22.6174C1.51992 22.7831 1.76378 22.8487 1.98889 22.7878L4.45018 22.1281C5.72263 22.9167 7.1905 23.3333 8.70213 23.3333C11.9653 23.3333 14.7674 21.5153 16.0164 18.5882C16.1579 18.2552 16.0024 17.8683 15.6694 17.7261Z"
                        fill="#00AAF6"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_2072:14785">
                        <rect
                            width="22.6667"
                            height="22.6667"
                            fill="white"
                            transform="translate(0.667969 0.666504)"
                        />
                    </clipPath>
                </defs>
            </svg>
        </div>
    )

    const RenderAngelina = () => {
        const languagesArray = [
            {
                type: 'lang',
                label: 'Українська',
                value: 'uk'
            },
            {
                type: 'lang',
                label: 'English',
                value: 'en'
            },
            {
                type: 'lang',
                label: 'русский',
                value: 'ru'
            }
        ]
        const dropdownItems = [
            // {
            //     key: 1,
            //     type: 'button',
            //     label: t('online'),
            //     id: 'online',
            //     onClick: () => {
            //         statusOperatorHandler('online')
            //     }
            // },
            // {
            //     key: 2,
            //     type: 'button',
            //     label: t('on-break'),
            //     id: 'on-break',
            //     onClick: () => {
            //         statusOperatorHandler('on-break')
            //     }
            // },
            { key: 3, type: 'langSelector' },
            { key: 4, type: 'divider' },
            {
                key: 5,
                type: 'button',
                label: t('logout'),
                Icon: ExitToApp,
                onClick: () => {
                    AuthService.signOut(() => {
                        dispatch({
                            type: INTERPRETER_STATE.SIGN_OUT
                        })
                    })
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                }
            }
        ]
        return (
            <div
                style={{
                    left: '-50px',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                {isAuth && (
                    <button
                        onClick={() => {
                            statusOperatorHandler(callState.operatorStatus)
                        }}
                        // container
                        // justifyContent="center"
                        // alignItems="center"
                        className={[
                            styles[
                                `operatorStatus_${callState.operatorStatus}`
                            ],
                            styles.operatorStatus
                        ].join(' ')}
                    >
                        <Typography
                            style={{
                                color: '#fff',
                                fontSize: '12px',
                                fontWeight: '600'
                            }}
                        >
                            {t(callState.operatorStatus)}
                        </Typography>
                    </button>
                )}

                <div className={styles.dropdown}>
                    <button className={styles.dropbtn}>
                        <div
                            style={{
                                minWidth: 200,
                                height: 26,
                                display: 'flex',
                                flexDirection: 'row',
                                alignSelf: 'center',
                                justifyContent: 'center',
                                color: '#A2ABB4'
                            }}
                        >
                            <Typography
                                style={{
                                    // color: '#fff',
                                    alignSelf: 'center',
                                    textAlign: 'center',
                                    fontSize: 12,
                                    fontWeight: '500',
                                    marginRight: 10,
                                    color: 'currentColor'
                                }}
                            >
                                {AuthService?.getUser()?.email}
                            </Typography>
                            {isAuth && (
                                <SettingsOutlined style={{ marginLeft: 6 }} />
                            )}
                        </div>
                    </button>
                    <div className={styles.dropdown_content}>
                        {dropdownItems.map((el, i) =>
                            el.type === 'divider' ? (
                                <Divider key={el.key} />
                            ) : el.type === 'langSelector' ? (
                                <div className={styles.left} key={el.key}>
                                    {t('changeLanguage')}
                                    <span className={styles.left_container}>
                                        {languagesArray.map((el) => (
                                            <span
                                                key={el.value}
                                                className={styles.left_item}
                                                onClick={() =>
                                                    i18n.changeLanguage(
                                                        el.value
                                                    )
                                                }
                                            >
                                                {el.label}
                                            </span>
                                        ))}
                                    </span>
                                </div>
                            ) : (
                                <div
                                    key={el.key}
                                    onClick={() =>
                                        el.type === 'lang'
                                            ? i18n.changeLanguage(el.value)
                                            : el.onClick()
                                    }
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent:
                                            el.type === 'button' && !el.id
                                                ? 'space-between'
                                                : 'flex-start',
                                        alignItems: 'center'
                                    }}
                                >
                                    {el &&
                                        el.id &&
                                        el.id === callState.operatorStatus && (
                                            <div
                                                className={
                                                    styles[
                                                        `operatorStatusIcon_${el.id}`
                                                    ]
                                                }
                                            ></div>
                                        )}
                                    {el.label}
                                    {el.Icon && (
                                        <el.Icon style={{ color: '#A2ABB4' }} />
                                    )}
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>
        )
    }

    const { backgroundColor, title } = props

    return (
        <Container backgroundColor={backgroundColor}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <HeaderTitle />
                <Text key="title-text">{title}</Text>
            </div>
            <RenderAngelina />
        </Container>
    )
}

Titlebar.defaultProps = {
    title: null,
    backgroundColor: '#000000'
}

Titlebar.propTypes = {
    title: PropTypes.string,
    backgroundColor: PropTypes.string
}

export default Titlebar
