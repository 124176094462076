import React, { useState } from 'react'
import { Box, ButtonBase, Divider, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'

import { useCallContext } from '../../reducers/Call/CallProvider'
import { CallService } from '../../services/CallService'
import { CALL_TYPE } from '../../constants/defaultConstants'

export const RenderContact = ({ contact }) => {
    const [errorAlert, setErrorAlert] = useState(false)
    const [isClientOnline, setIsClientOnline] = useState(true)
    const { callState, callSocket, makeOutGoingCall } = useCallContext()
    const { selectedCall } = callState
    const { t } = useTranslation()

    const makeCall = async (contact) => {
        const { login } = contact
        const res = await CallService.getContactData(login)

        console.log('contactData ', res)

        if (!res) {
            setErrorAlert(true)
            return
        }

        const { id } = contact
        const createCallObj = {
            type: CALL_TYPE.OUTGOING_CALL,
            data: { clientId: id }
        }

        callSocket.emit('new:call:create', createCallObj, (payload) => {
            console.log('new:call:create ', payload)
            makeOutGoingCall(payload)
        })
    }

    return (
        <div>
            <ButtonBase
                disabled={!!selectedCall.sessionName}
                style={{
                    width: '100%',
                    paddingTop: 10,
                    backgroundColor:
                        (errorAlert && 'rgb(253, 236, 234)') ||
                        (!isClientOnline && ' rgb(255, 244, 229)')
                }}
                onClick={() => makeCall(contact)}
            >
                <Box
                    style={{
                        paddingLeft: 10,
                        paddingRight: 10,
                        width: '100%'
                    }}
                >
                    <Typography variant="body1" align="left">
                        {contact?.profile?.last_name}{' '}
                        {contact?.profile?.first_name}{' '}
                        {contact?.profile?.patronymic}
                    </Typography>
                    <Typography variant="body1" align="left">
                        {contact?.employee?.department?.name}
                    </Typography>
                    <Typography variant="body1" align="left">
                        {contact.login}
                    </Typography>

                    <Divider
                        style={{
                            width: '100%'
                        }}
                    />
                </Box>
            </ButtonBase>
            {errorAlert && (
                <Alert severity="error" onClose={() => setErrorAlert(false)}>
                    {t('wrongNumber')}
                </Alert>
            )}
            {!isClientOnline && (
                <Alert
                    severity="warning"
                    onClose={() => setIsClientOnline(true)}
                >
                    {t('clientOffline')}
                </Alert>
            )}
        </div>
    )
}
