import React, { useEffect, useContext } from 'react'
import MainFrame from './MainFrame'
import AuthReducer from './reducers/AuthReducer'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider, createTheme } from '@material-ui/core'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import Titlebar from './components/Titlebar/Titlebar'
import { store } from './reducers/store'
import { Provider } from 'react-redux'
import { CallProvider } from './reducers/Call/CallProvider'
import ZoomContext from './context/zoom-context'

const title = document.getElementsByTagName('title')[0]
const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#2196F3 !important'
        }
    },
    typography: {
        fontSize: 14,
        fontFamily: 'Roboto',
        h3: {
            textAlign: 'center',
            alignSelf: 'center',
            fontWeight: '300',
            fontSize: 40
        },
        h4: {
            textAlign: 'center',
            alignSelf: 'center',
            userSelect: 'none',
            fontWeight: '500',
            color: '#fff'
        },
        h5: {
            textAlign: 'center',
            alignSelf: 'center',
            color: 'rgba(0, 0, 0, 0.54)',
            userSelect: 'none'
        },
        h6: {
            textAlign: 'left',
            alignSelf: 'center',
            fontSize: 20,
            fontWeight: '400'
        },
        subtitle1: {
            fontSize: 14,
            color: '#757575',
            alignSelf: 'left',
            textAlign: 'left',
            letterSpacing: 0.25,
            fontWeight: '400'
        },
        subtitle2: {
            fontSize: 14,
            color: '#fff'
        },
        body1: {
            fontSize: 16,
            alignSelf: 'left',
            textAlign: 'left',
            color: 'rgba(0, 0, 0, 0.87)',
            letterSpacing: 0.15
        },
        body2: {
            fontSize: 16,
            fontWeight: 400,
            wordBreak: 'break-word',
            textAlign: 'left'
        },
        button: {
            fontSize: 10,
            fontWeight: '500',
            width: 64,
            textTransform: 'capitalize'
        }
    }
})

export function App() {
    console.log('node env', process.env.REACT_APP_ENVIRONMENT)
    console.log('node base url', process.env.REACT_APP_BASE_URL)
    return (
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <AuthReducer>
                    <CallProvider>
                        <Titlebar
                            title={title.innerText}
                            backgroundColor="#fff"
                        />
                        <CssBaseline />
                        <MainFrame />
                    </CallProvider>
                </AuthReducer>
            </Provider>
        </ThemeProvider>
    )
}
