import { useEffect, useState, useContext, useCallback } from 'react'
import ZoomVideo from '@zoom/videosdk'
import { Box } from '@material-ui/core'
import ZoomContext from '../context/zoom-context'
import { startVideo, startAudio } from './helpers'
import { useCallContext } from '../reducers/Call/CallProvider'
import AudioOnIcon from '../assets/sound-off-icon.svg'
import AudioOffIcon from '../assets/volumeIconOff.svg'
import DeclineBtn from '../assets/declineButton.svg'

let stream
let audioEncode
let audioDecode

export const ZoomCall = ({
    topic,
    token,
    userName = 'Operator',
    password = '12345678',
    isForceDisconnect = false
}) => {
    const zmClient = useContext(ZoomContext)
    const [isUserMute, setIsUserMute] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const {
        streamAvailable,
        setStreamAvailable,
        isOperatorMuted,
        setIsOperatorMuted,
        isCameraOff,
        setIsCameraOff,
        endCall,
        forceDisconnect
    } = useCallContext()

    const handleForceDisconnect = useCallback(() => {
        // return;

        forceDisconnect(topic)
        zmClient.leave()
        setIsLoading(false)
        endCall()
    }, [forceDisconnect, topic, zmClient, endCall])

    const init = useCallback(async () => {
        setIsOperatorMuted(false)
        setIsCameraOff(false)
        
        await zmClient.init('en-US', 'CDN')
        // console.log('topic, token', { topic, token })
        try {
            //setStreamAvailable(false)
            setIsLoading(true)
            await zmClient
                .join(topic, token, userName, password)
                .then(async (d) => {
                    console.log('init then',d)
                    stream = zmClient.getMediaStream()
                    console.log('stream init', stream)
                    await startVideo(stream, zmClient)
                    await startAudio(stream, audioEncode, audioDecode)
                })
                .catch((e) => {
                    console.log('Error in join session ', e)
                    window.location.reload()
                })
            // stream = zmClient.getMediaStream()
            // console.log('stream init', stream)
            // await startVideo(stream, zmClient)
            // await startAudio(stream, audioEncode, audioDecode)
        } catch (error) {
            console.log('Error in init', error)
        }
    }, [
        password,
        setIsOperatorMuted,
        token,
        topic,
        userName,
        zmClient,
        setIsCameraOff
    ])

    useEffect(() => {
        if (!topic || !token) {
            return
        }

        init()

        return () => {
            ZoomVideo.destroyClient()
        }
    }, [
        password,
        setIsOperatorMuted,
        setStreamAvailable,
        token,
        topic,
        userName,
        zmClient,
        init
    ])

    useEffect(() => {
        const onUserAdded = async (payload) => {
            console.log(payload[0].userId + ' joined the session ', payload[0])
        }

        const onUserRemoved = (payload) => {
            //console.log('USERS---> ', payload.length)
            console.log(payload[0]?.userId + ' left the session')
            if (payload[0]?.userId) {
                endCall()
                zmClient.leave(true)
            }
        }
        const onUserUpdated = (payload) => {
            //console.log(payload[0].userId + ' properties were updated')
            //console.log('properties: ', payload[0])
        }

        const onVideoStateChange = async (payload) => {
            console.log('Video State Change Payload:', payload)

            if (!stream) {
                console.warn('Stream is not defined.')
                // Attempt to reinitialize the stream
                try {
                    stream = zmClient.getMediaStream()
                    console.log('Reinitialized stream:', stream)
                } catch (error) {
                    console.error('Error reinitializing stream:', error)
                    return
                }
            }

            if (payload.action === 'Start') {
                try {
                    await stream.renderVideo(
                        document.querySelector('#participants-canvas'),
                        payload.userId,
                        1920,
                        1080,
                        0,
                        0,
                        3
                    )
                    setStreamAvailable(true)
                    setIsLoading(false)
                } catch (err) {
                    console.error('Error in renderVideo:', err)
                }
            }
        }

        // const onVideoStateChange = async (payload) => {
        //     if (payload.action === 'Start') {
        //         // console.log('stream', stream)
        //         // if (!stream) {
        //         //     stream = zmClient.getMediaStream()
        //         // }
        //         await stream
        //             .renderVideo(
        //                 document.querySelector('#participants-canvas'),
        //                 payload.userId,
        //                 1920,
        //                 1080,
        //                 0,
        //                 0,
        //                 3
        //             )
        //             .then(() => {
        //                 setStreamAvailable(true)
        //                 setIsLoading(false)
        //             })
        //             .catch((err) => {
        //                 console.log('VideoStateChange ', err)
        //             })
        //     }
        // }

        const onMediaSDKChange = (payload) => {
            if (payload.type === 'audio' && payload.result === 'success') {
                if (payload.action === 'encode') {
                    // encode for sending audio stream (speak)
                    audioEncode = true
                    console.log(' audioEncode ', audioEncode)
                } else if (payload.action === 'decode') {
                    // decode for receiving audio stream (hear)
                    audioDecode = true
                    console.log(' audioDecode ', audioDecode)
                }
            }
        }

        const onAutoPlayAudioFailed = () => {
            console.log('auto play failed, waiting for a user interaction')
        }

        // const onActiveSpeaker = (payload) => {
        //     console.log('Active speaker', payload)
        // }

        zmClient.on('auto-play-audio-failed', onAutoPlayAudioFailed)
        zmClient.on('media-sdk-change', onMediaSDKChange)
        zmClient.on('peer-video-state-change', onVideoStateChange)
        zmClient.on('user-added', onUserAdded)
        zmClient.on('user-removed', onUserRemoved)
        zmClient.on('user-updated', onUserUpdated)
        //zmClient.on('active-speaker', onActiveSpeaker)

        return () => {
            zmClient.off('user-added', onUserAdded)
            zmClient.off('user-removed', onUserRemoved)
            zmClient.off('user-updated', onUserUpdated)
            zmClient.off('peer-video-state-change', onVideoStateChange)
            zmClient.off('media-sdk-change', onMediaSDKChange)
            zmClient.off('auto-play-audio-failed', onAutoPlayAudioFailed)
            //zmClient.off('active-speaker', onActiveSpeaker)
        }
    }, [
        zmClient,
        endCall,
        setIsOperatorMuted,
        setIsCameraOff,
        setStreamAvailable
    ])

    useEffect(() => {
        if (!zmClient.getSessionInfo().isInMeeting) {
            return
        }

        const checkIsOperatorMuted = async () => {
            try {
                if (isOperatorMuted) {
                    await stream.muteAudio()
                } else {
                    await stream.unmuteAudio()
                }
            } catch (error) {
                console.log('checkIsOperatorMuted', error)
            }
        }

        checkIsOperatorMuted()
    }, [isOperatorMuted, zmClient])

    useEffect(() => {
        if (!zmClient.getSessionInfo().isInMeeting) {
            return
        }
        console.log('stream', stream)
        const checkIsCameraOff = async () => {
            try {
                if (isCameraOff) {
                    await stream.stopVideo()
                } else {
                    await startVideo(stream, zmClient)
                }
            } catch (error) {
                console.log('checkIsCameraOff', error)
            }
        }

        checkIsCameraOff()
    }, [zmClient, isCameraOff])

    //force disconnect
    useEffect(() => {
        let timerId

        if (!isLoading) {
            clearTimeout(timerId)
            return
        }

        timerId = setTimeout(() => {
            handleForceDisconnect()
        }, 20000)

        return () => {
            clearTimeout(timerId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])

    useEffect(() => {
        if (isForceDisconnect) {
            handleForceDisconnect()
        }
    }, [isForceDisconnect, handleForceDisconnect])

    const changeAudioLocally = async () => {
        try {
            const participants = zmClient.getAllUser()
            const operator = zmClient.getCurrentUserInfo()
            const [amsaanUser] = participants.filter(
                (user) => user.userId !== operator.userId
            )
            if (isUserMute) {
                await stream.unmuteUserAudioLocally(amsaanUser.userId)
                setIsUserMute(false)
                console.log('changeAudioLocally unmute')
            } else {
                await stream.muteUserAudioLocally(amsaanUser.userId)
                setIsUserMute(true)
                console.log('changeAudioLocally mute')
            }
        } catch (error) {
            console.log('Error in changeAudioLocally ', error)
        }
    }

    const onDeclineButtonClick = () => {
        try {
            endCall()
            zmClient.leave()
        } catch (error) {
            console.log('error in onDeclineButtonClick ', error)
        }
    }

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
        >
            <video
                style={{
                    display: 'none'
                }}
                id="self-view-video"
            ></video>
            <canvas
                style={{
                    display: 'none'
                }}
                id="self-view-canvas"
            ></canvas>
            <div style={styles.canvasWrapper}>
                <canvas
                    style={styles.participantsCanvas}
                    id="participants-canvas"
                    width="1920"
                    height="1080"
                ></canvas>
            </div>
            {streamAvailable && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={styles.streamBox}
                >
                    {' '}
                    <button onClick={changeAudioLocally} style={styles.button}>
                        <img
                            src={isUserMute ? AudioOnIcon : AudioOffIcon}
                            alt="Audio"
                        />
                    </button>
                    <button
                        onClick={onDeclineButtonClick}
                        style={styles.button}
                    >
                        <img src={DeclineBtn} alt="Decline" />
                    </button>
                </Box>
            )}
        </Box>
    )
}

const styles = {
    button: {
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        margin: '0 10px'
    },
    streamBox: {
        position: 'absolute',
        bottom: '50px',
        left: '50%',
        transform: 'translate(-50%, 0)'
    },
    participantsCanvas: {
        width: '200%',
        height: 'auto',
        display: 'block',
        maxHeight: 'calc(100vh - 40px)'
    },
    canvasWrapper: {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden'
    }
}
