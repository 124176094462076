import { Box } from '@material-ui/core'

export const CounterMissedCalls = ({ count }) => {
    return (
        <Box
            style={{
                position: 'absolute',
                top: 10,
                right: 50,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 20,
                height: 20,
                borderRadius: '50%',
                backgroundColor: 'red',
                color: '#ffffff',
                fontWeight: 'bold',
                fontSize: 12
            }}
        >
            {count}
        </Box>
    )
}
