import React, { useEffect, useState } from 'react'
import { ButtonBase } from '@material-ui/core'
import { RenderContact } from './RenderContact'
import { SearchInput } from '../SearchInput'
import { CallService } from '../../services/CallService'
import { useCallContext } from '../../reducers/Call/CallProvider'
import { CallActionTypes } from '../../reducers/Call/CallActionsTypes'
import { Alert } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'

export const TabContacts = () => {
    const { callState, dispatchCall } = useCallContext()
    const [page, setPage] = useState(1)
    const [error, setError] = useState(false)
    const { contacts } = callState
    const { t } = useTranslation()

    useEffect(() => {
        const getAllContacts = async (pageNumber) => {
            const contacts = await CallService.getContacts(pageNumber)
            dispatchCall({
                type: CallActionTypes.GET_CONTACTS,
                payload: contacts
            })
        }
        getAllContacts(page)
    }, [dispatchCall, page])

    useEffect(() => {
        return () => {
            dispatchCall({ type: CallActionTypes.CLEAR_CONTACTS })
        }
    }, [dispatchCall])

    const getFoundedContact = async (value) => {
        setError(false)
        const res = await CallService.getContactData(value)
        if (!res) {
            dispatchCall({ type: CallActionTypes.CLEAR_CONTACTS })
            setError(true)
            return
        }
        dispatchCall({
            type: CallActionTypes.FIND_CONTACT,
            payload: res
        })
    }

    const handleClick = async (value) => {
        if (value.length) {
            try {
                value = value.replace(/[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/, '')
                setPage(0)
                dispatchCall({ type: CallActionTypes.CLEAR_CONTACTS })
                getFoundedContact(value)
            } catch (err) {
                dispatchCall({
                    type: CallActionTypes.GET_CONTACTS,
                    payload: { data: [] }
                })
            }
        }
    }

    const loadNextContacts = () => {
        setError(false)
        setPage((page) => page + 1)
    }
    return (
        <div
            style={{
                overflow: 'scroll',
                marginBottom: 196
            }}
        >
            <SearchInput handleClick={handleClick} />

            <ButtonBase
                onClick={loadNextContacts}
                style={{
                    width: '100%',
                    bottom: 0,
                    height: 50,
                    backgroundColor: '#00000030'
                }}
            >
                {t('downloadMore')}
            </ButtonBase>

            {contacts.length !== 0 &&
                contacts.map((contact) => (
                    <RenderContact contact={contact} key={contact?.id} />
                ))}

            {error && (
                <Alert severity="error">This contact doesn't exist</Alert>
            )}
        </div>
    )
}
