import React from "react";
import { getCountryFlag } from "../utils";
import { languageType } from "../types";

interface Props {
    language: languageType,
    user?:any
}

export function FlagChip({ language,user }: Props) {
    let userLang = language

    if (user && user.app === 'amsaan') {
        userLang = user?.languages[0]
    }

    let userCountryFlag = null

    if (userLang) {
        userCountryFlag = getCountryFlag(userLang)
    }

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                // marginBottom: 15
            }}
        >
            <img
                src={userCountryFlag}
                alt={userLang}
                style={{ marginRight: 5,width:'20px' }}
            />
            {userLang.toUpperCase()}
        </div>
    )
}