import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Typography, makeStyles, Paper, Button } from '@material-ui/core'
import { TranslationsService } from '../../services/TranslationsService'
import Icon from '../../assets/translation_icon.svg'
import { SearchInput } from '../SearchInput'
import { useActions } from '../../hooks/useActions'
import { Transition } from 'react-transition-group'
import { applyTranslationFormat } from '../../utils/CustomDate'
import TranslationIconComplete from '../../assets/translation_icon_complete.svg'
import TranslationIconPending from '../../assets/translation_icon_pending.svg'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    search: {
        // padding: '7px 19px',
        marginBottom: '-1px',
        width: '100%',
        borderWidth: '2px',
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent'
    },
    paper: {
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            background: '#fafafa'
        },
        '&:focus': {
            background: '#f1f1f6'
        }
    },
    textContent: {
        width: '100%',
        borderWidth: '1px',
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        background: 'inherit'
    },
    iconCircle: {
        border: '1px solid #B9B9B9',
        borderRadius: '50%',
        width: '50px',
        height: '40px',
        margin: '12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    icon: {
        width: '20px',
        height: '20px'
    },
    requestString: {
        display: 'flex',
        gap: '3px'
    },
    loadMore: {
        width: 'max-content',
        alignSelf: 'center',
        marginTop: '50px',
        textTransform: 'uppercase'
    }
})

const request = (quantity) => {
    const translationsList = []

    for (let i = 0; i < quantity; i++) {
        translationsList.push({
            id: i + 1,
            user_id: i + 1,
            status: 'created',
            requestNumber: i + 1,
            created_at: '01.01.2022',
            files: [
                {
                    file: `https://images.unsplash.com/photo-1571501679680-de32f1e7aad4?w=600&h=600`
                },
                {
                    file: `https://images.unsplash.com/photo-1571501679680-de32f1e7aad4?w=600&h=600`
                },
                {
                    file: `https://images.unsplash.com/photo-1571501679680-de32f1e7aad4?w=600&h=600`
                }
            ],
            // files: [
            // 	`https://images.unsplash.com/photo-1571501679680-de32f1e7aad4?w=${i + 1}00&h=${i + 1}00`,
            // 	`https://images.unsplash.com/photo-1571501679680-de32f1e7aad4?w=1000&h=1000`,
            // 	`https://images.unsplash.com/photo-1571501679680-de32f1e7aad4?w=${i + 1}00&h=${i + 1}00`
            // ],
            records: [null, null, null]
        })
    }

    return translationsList
}

export const TabTranslations = () => {
    const { t } = useTranslation()
    const classes = useStyles()
    const { translationsGetAll, translationsGetUsers, selectTranslation } =
        useActions()
    const { all, users, selectedId } = useSelector(
        (state) => state.translationsReducer
    )
    const { isCameraEnabled } = useSelector((state) => state.recordsReducer)
    const duration = 500
    const [page, setPage] = useState(1)

    /* start */
    // useEffect(() => {
    // 	(async function() {
    // 		// const translationsResponse = await TranslationsService.getAllRequests();
    // 		// const usersResponse = await TranslationsService.getAllUsers(2);
    // 		// const findUser = userId => usersResponse.items.find(user => user.id === userId);
    // 		// const translationsWithUsers = translationsResponse.data.map(translation => ({
    // 		// 	...translation,
    // 		// 	user: findUser(translation.user_id)
    // 		// }));
    // 		const translationsResponse = request(10);
    // 		// const translationsWithUsers = translationsResponse.map(translation => ({
    // 		// 	...translation,
    // 		// 	user: findUser(translation.user_id)
    // 		// }));

    // 		translationsGetAll(translationsResponse);
    // 		// translationsGetAll(translationsWithUsers);
    // 		// translationsGetUsers(usersResponse);
    // 	})();
    // }, []);
    /* end */

    // async function getTranslations() {
    //     const translationsResponse = await TranslationsService.getAllRequests(
    //         page
    //     )
    //     const translationsWithUsers = []

    //     if (translationsResponse) {
    //         for (let i = 0; i < translationsResponse.data.length; i++) {
    //             const user = await TranslationsService.getUser(
    //                 translationsResponse.data[i].user_id
    //             )
    //             const videos =
    //                 translationsResponse.data[i].translationResponse.files
    //             // console.log(videos, 'videos');

    //             translationsWithUsers.push({
    //                 ...translationsResponse.data[i],
    //                 user,
    //                 records: videos ? videos : [null, null, null]
    //             })
    //         }
    //     }
    //     translationsGetAll(translationsWithUsers)
    // }
    // useEffect(() => {
    //     getTranslations()

    //     return () => translationsGetAll([])
    // }, [page])

    const search = async (query) => {
        const translationsResponse = await TranslationsService.getAllRequests()
        const newAll = translationsResponse.data.filter((item) =>
            item.id.toString().includes(query)
        )
        translationsGetAll(newAll)
    }

    const defaultStyles = {
        display: 'flex',
        flexDirection: 'column',
        transition: `all ${duration}ms ease-in-out`
    }

    const transitionStyles = {
        entering: {
            marginTop: '300px'
        },
        entered: {
            marginTop: '300px'
        },
        exiting: {
            marginTop: '0'
        },
        exited: {
            marginTop: '0'
        }
    }

    const setStatusIcon = (item) => {
        switch (item.status) {
            case 'created':
                return TranslationIconPending
            case 'processing':
                return Icon
            case 'completed':
                return TranslationIconComplete
        }
    }

    return (
        all && (
            <Transition in={isCameraEnabled} timeout={duration}>
                {(state) => (
                    <Box
                        // className={classes.root}
                        style={{
                            ...defaultStyles,
                            ...transitionStyles[state]
                        }}
                    >
                        <Paper
                            variant="outlined"
                            square
                            className={classes.search}
                        >
                            <SearchInput handleClick={search} />
                        </Paper>

                        {all.map((item) => (
                            <Box
                                key={item.id}
                                className={classes.paper}
                                onClick={() => selectTranslation(item.id)}
                                tabIndex="0"
                            >
                                <Box className={classes.iconCircle}>
                                    <Box
                                        component="img"
                                        className={classes.icon}
                                        alt="translation status icon"
                                        src={setStatusIcon(item)}
                                    />
                                </Box>
                                <Paper
                                    className={classes.textContent}
                                    variant="outlined"
                                    square
                                >
                                    <Typography>
                                        {item.user?.member
                                            ? `${item.user.member.lastName} ${item.user.member.firstName} ${item.user.member.patronymic}`
                                            : t('translationsTab.notProvided')}
                                    </Typography>

                                    <Typography>{item.user?.login}</Typography>

                                    <Box className={classes.requestString}>
                                        <Typography color="textSecondary">
                                            {t('translationsTab.orderNumber')}
                                        </Typography>

                                        <Typography>{item.id}</Typography>
                                    </Box>

                                    <Typography color="textSecondary">
                                        {applyTranslationFormat(
                                            item.updated_at
                                        )}
                                    </Typography>
                                </Paper>
                            </Box>
                        ))}
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.loadMore}
                            //onClick={() => setPage(page + 1)}
                        >
                            {t('downloadMore')}
                        </Button>
                    </Box>
                )}
            </Transition>
        )
    )
}
