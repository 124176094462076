export enum CallActionTypes {
    SET_INCOMING_CALLS = 'SET_CALLS',
    TAKE_CALL = 'TAKE_CALL',
    TAKE_CALL_FROM_INCOMINGS = 'TAKE_CALL_FROM_INCOMINGS',
    END_CALL = 'END_CALL',
    PREVIEW_CALL = 'PREVIEW_CALL',
    DELETE_CALL = 'DELETE_CALL',
    GET_CONTACTS = 'GET_CONTACTS',
    FIND_CONTACT = 'FIND_CONTACT',
    CLEAR_CONTACTS = 'CLEAR_CONTACTS',
    SET_CALL_STATUS = 'SET_CALL_STATUS',
    GET_CALL_HISTORY = 'GET_CALL_HISTORY',
    SET_SELECTED_CONTACT = 'SET_SELECTED_CONTACT',
    CLEAR_CALL_HISTORY = 'CLEAR_CALL_HISTORY',
    SET_OTHERS_CALLS = 'SET_OTHERS_CALLS',
    SET_QUESTIONER = 'SET_QUESTIONER',
    REMOVE_QUESTIONER = 'REMOVE_QUESTIONER',
    SET_SELECTED_HISTORY = 'SET_SELECTED_HISTORY',
    SET_OPERATOR_STATUS = 'SET_OPERATOR_STATUS',
    SET_OPERATOR_TOKEN = 'SET_OPERATOR_TOKEN',
    SET_SELECTED_CALL = 'SET_OUTGOING_CALL'
}
