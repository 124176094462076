import React from 'react'

import { CallActionTypes } from '../../reducers/Call/CallActionsTypes'
import { useCallContext } from '../../reducers/Call/CallProvider'
import { CallService } from '../../services/CallService'
import { useEffect } from 'react'

import { CallHistoryItems } from './CallHistoryItems'

export const CallHistory = ({ isHistoryRender }) => {
    const { dispatchCall } = useCallContext()

    useEffect(() => {
        async function getHistory() {
            const res = await CallService.getCallHistory()
            console.log('HISTORY RES', res)

            if (res) {
                res.reverse()
                dispatchCall({
                    type: CallActionTypes.GET_CALL_HISTORY,
                    payload: res
                })
            }
        }

        getHistory()
    }, [dispatchCall])

    useEffect(() => {
        return () => {
            dispatchCall({ type: CallActionTypes.CLEAR_CALL_HISTORY })
        }
    }, [dispatchCall])

    return (
        <div
            style={{
                overflow: 'scroll',
                marginBottom: 347
            }}
        >
            <CallHistoryItems />
        </div>
    )
}
