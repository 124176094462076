import React, { Fragment, useContext } from 'react'
import SignIn from './pages/signin/SignIn'
import { AuthContext } from './reducers/AuthReducer'
import { Box } from '@material-ui/core'
import { BasePanel } from './components/BasePanel'
import { useCallContext } from './reducers/Call/CallProvider'
import { ZoomOperatorsPanel } from './components/ZoomOperatorsPanel/ZoomOperatorsPanel'
import { CallPage } from './pages/CallPage/CallPage'
import BookingModal from './components/BookingModal/BookingModal'

export default function MainFrame() {
    const [authState] = useContext(AuthContext)
    const { streamAvailable } = useCallContext()

    let authenticated
    if (authState.success !== undefined) {
        authenticated = authState.success
    } else {
        authenticated = authState.authenticated()
    }
    return (
        <Fragment>
            {authenticated ? (
                <>
                    <BookingModal />
                    <Box
                        style={{
                            justifyContent: 'flex-start',
                            flexDirection: 'row',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flex: 1
                        }}
                    >
                        <Box>
                            <BasePanel authState={authState} />
                        </Box>
                        <Box style={{ width: '100%' }}>
                            {/* <PageRouter /> */}
                            <CallPage />
                        </Box>
                        {streamAvailable && (
                            <Box>
                                <ZoomOperatorsPanel authState={authState} />
                            </Box>
                        )}
                    </Box>
                </>
            ) : (
                <SignIn />
            )}
        </Fragment>
    )
}
