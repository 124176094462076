import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@material-ui/core'

import CancelCallIcon from '../../assets/declineButton.svg'
import { useCallContext } from '../../reducers/Call/CallProvider'
import { Timer } from '../../utils/Timer'
import { useTranslation } from 'react-i18next'

export const OutGoingCallPreview = ({ startedAt, handleCancel }) => {
    const [userNumber, setUserNumber] = useState('')
    const { t } = useTranslation()
    const { callState, forceDisconnect, callSocket, setIsInStream, endCall } =
        useCallContext()
    const { selectedCall } = callState

    useEffect(() => {
        const users = selectedCall?.listeners
        if (users) {
            const targetedClient = users.find((user) => user.type === 'caller')
            const targetedClientNumber = targetedClient?.properties?.name ?? ''
            setUserNumber(targetedClientNumber)
        }
    }, [selectedCall])

    useEffect(() => {
        const socketCall = callSocket
        if (!socketCall) {
            return
        }
        socketCall.on('call:answered', (payload) => {
            console.log('user answered')
            setIsInStream(true)
        })

        return () => {
            socketCall.off('call:answered')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callSocket])

    const handleCancelCall = () => {
        const { sessionName } = selectedCall
        console.log('Operator cancel outgoing call')
        forceDisconnect(sessionName)
        endCall()
    }

    return (
        <Box
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingTop: 100,
                paddingBottom: 100
            }}
        >
            <Typography variant="h4" style={{ color: '#000' }}>
                {t('call.outgoing')}
            </Typography>
            <Typography variant="h4" style={{ color: '#000' }}>
                {userNumber}
            </Typography>
            <Box alignItems="center">
                <Typography
                    align="center"
                    variant="subtitle1"
                    style={{ color: '#757575' }}
                >
                    {t('call.waiting')}
                </Typography>
                <Typography align="center" variant="h3">
                    <Timer start={startedAt} />
                </Typography>
            </Box>
            <Box
                style={{
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Box>
                    <Box
                        style={{
                            width: 180,
                            position: 'relative'
                        }}
                    >
                        <img
                            onClick={handleCancelCall}
                            src={CancelCallIcon}
                            alt=""
                            style={{
                                width: 120,
                                height: 120,
                                borderRadius: '50%',
                                alignSelf: 'center',
                                boxShadow:
                                    '0px 11px 15px rgba(0, 0, 0, 0.2), 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12)',
                                position: 'relative',
                                left: '17%',
                                bottom: '19%',
                                cursor: 'pointer'
                            }}
                        />
                    </Box>
                    <Typography
                        align="center"
                        style={{ color: '#757575', marginTop: 20 }}
                    >
                        {t('call.endCall')}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}
