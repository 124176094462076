import React from 'react'
import { Grid } from '@material-ui/core'
import { InterpreterPanel } from './InterpreterPanel'
import { TabsPanel } from './TabsPanel'

export const BasePanel = ({ authState }) => {
    return (
        <Grid item container xs={3} direction="column">
            <InterpreterPanel authState={authState} />
            <TabsPanel />
        </Grid>
    )
}
