import React, { useState, useEffect, useContext } from 'react'
import { Box, Grid } from '@material-ui/core'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'

import ZoomContext from '../../context/zoom-context'
import ChatIcon from '../../assets/chatIcon.svg'
import ChatIconInactive from '../../assets/ChatInactive.svg'
import { Chat } from './Chat'

export const ZoomOperatorsPanel = () => {
    const zmClient = useContext(ZoomContext)
    const [messages, setMessages] = useState([])
    const [value, setValue] = useState(false)

    useEffect(() => {
        const messageReceiver = (payload) => {
            setMessages((prev) => [
                { from: payload.sender.name, data: payload.message },
                ...prev
            ])
            setValue(0)
            // console.log(payload)
        }

        zmClient.on('chat-on-message', messageReceiver)

        return () => {
            zmClient.off('chat-on-message', messageReceiver)
        }
    }, [zmClient])

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const closeTab = () => {
        setValue(false)
    }

    return (
        <>
            <Box
                style={{
                    margin: 0,
                    padding: 0,
                    backgroundColor: 'transparent'
                }}
            >
                <Box
                    flexDirection="row-reverse"
                    display="flex"
                    style={{ backgroundColor: 'transparent' }}
                >
                    <Box display="flex" style={{ width: 50 }}>
                        <Tabs
                            selectionFollowsFocus={true}
                            orientation="vertical"
                            value={value}
                            onChange={handleChange}
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: 'transparent'
                                }
                            }}
                        >
                            <Tab
                                icon={
                                    value === 0 ? (
                                        <img src={ChatIcon} alt="" />
                                    ) : (
                                        <img src={ChatIconInactive} alt="" />
                                    )
                                }
                                style={{
                                    minWidth: 15,
                                    fontSize: 8
                                }}
                            />
                            <Tab
                                disabled={true}
                                style={{
                                    height: 0
                                }}
                            />
                        </Tabs>
                    </Box>
                    {/* ---------------------------------------------- */}
                    <Box
                        display="inline-block"
                        style={{ backgroundColor: 'transparent' }}
                    >
                        <TabPanel
                            value={value}
                            index={0}
                            style={{ backgroundColor: 'rgba(255,255,255,0)' }}
                        >
                            <Chat
                                closeTab={closeTab}
                                messages={messages}
                                setMessages={setMessages}
                            />
                        </TabPanel>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
}

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <Paper
            square
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            style={{
                height: 'calc(10vh - 70ps -55px)'
            }}
            {...other}
        >
            <Grid container direction="column">
                {value === index && children}
            </Grid>
        </Paper>
    )
}
