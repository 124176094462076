import { Box } from '@material-ui/core'
import React from 'react'
import { useCallContext } from '../reducers/Call/CallProvider'

export const TabWrapper = ({ children, value }) => {
    const { callState } = useCallContext()
    const { selectedCall, incomingCalls } = callState
    const isVideoActive =
        !!incomingCalls.length || !!selectedCall.sessionName.length
    const height = value === 1 ? 80 : 0
    return (
        <Box
            style={{
                overflowY: 'auto',
                width: 356,
                height: isVideoActive
                    ? `calc(100% - 236px - ${height}px)`
                    : `calc(100% - ${height}px)`
            }}
        >
            {children}
        </Box>
    )
}
