import { Box, ButtonBase, Typography } from '@material-ui/core'
import React from 'react'
import { getCallReason, recognizeCallType } from '../utils'
import { ReasonChip } from './ReasonChip'
import { FlagChip } from './FlagChip'
import moment from 'moment'
import { SessionData } from 'src/types'
import { Timer } from 'src/utils/Timer'
import { useTranslation } from 'react-i18next'
import { CALL_TYPE } from 'src/constants/defaultConstants'

interface Props {
    sessionData: SessionData
    selectedCall: SessionData
    handleSetCall: (el: SessionData) => void
    isRenderSelected: boolean
}

export function CallItem({
    sessionData,
    selectedCall,
    handleSetCall,
    isRenderSelected
}: Props) {
    const { t } = useTranslation()

    const callDate = moment(new Date(sessionData.call.createdAt)).format(
        'DD.MM.YYYY / HH:mm'
    )
    const isGroupCall = sessionData.reason === 'group'
    const { icon, text } = getCallReason(sessionData.reason)

    return (
        <ButtonBase
            style={{
                width: '100%',
                padding: '10px',
                gap: 8,
                borderBottom: '1px solid #ccc',
                backgroundColor:
                    sessionData.sessionName === selectedCall.sessionName &&
                    sessionData.status === 'answered'
                        ? '#008DFF30'
                        : sessionData?.status === 'reserved'
                        ? '#00000030'
                        : isGroupCall
                        ? '#E7AC39'
                        : 'transparent'
            }}
            disabled={
                isRenderSelected ||
                sessionData?.status === 'reserved' ||
                selectedCall.type === CALL_TYPE.OUTGOING_CALL
            }
            onClick={handleSetCall.bind(null, sessionData)}
        >
            <Box
                style={{
                    width: 100,
                    marginRight: 20,
                    marginLeft: 10,
                    height: 'auto',
                    display: 'flex',
                    gap: 10,
                    flexDirection: 'column',
                    position: 'relative',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <img
                    src={recognizeCallType({
                        type: sessionData.type,
                        app: sessionData.user.app
                    })}
                    alt={''}
                    style={{ width: '50px' }}
                />
                {icon && text && <ReasonChip icon={icon} text={text} />}
                {sessionData.lang ? (
                    <FlagChip
                        language={sessionData.lang}
                        user={sessionData.user}
                    />
                ) : null}
            </Box>

            <Box
                style={{
                    padding: 0,
                    width: '100%'
                }}
            >
                {sessionData.user?.employee ? (
                    <Typography variant="body1">
                        {sessionData.user?.employee?.department?.name}
                    </Typography>
                ) : null}
                <Typography variant="body1">
                    {sessionData.user?.profile?.lastName}{' '}
                    {sessionData.user?.profile?.firstName}{' '}
                    {sessionData.user?.profile?.patronymic}
                </Typography>
                <Typography variant="body1">
                    {sessionData?.user?.login ?? sessionData.user?.device?.name}
                </Typography>
                {text && <Typography variant="subtitle1">{t(text)}</Typography>}
                <Typography variant="body1">
                    {callDate} {' / '}
                    <Timer start={new Date(sessionData.startedAt)} />
                </Typography>
            </Box>
        </ButtonBase>
    )
}
