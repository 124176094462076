import React, { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { HistoryOutlined, RingVolume } from '@material-ui/icons'
import Paper from '@material-ui/core/Paper'
import { CounterMissedCalls } from './CounterMissedCalls'
import { useCallContext } from '../reducers/Call/CallProvider'
import { RenderCalls } from '../pages/CallPage/RenderCalls'
import PropTypes from 'prop-types'
import { CallHistory } from '../pages/CallPage/CallHistory'
import { useTranslation } from 'react-i18next'

export const TabIncoming = () => {
    const [isHistoryRender, setIsHistoryRender] = useState(0)
    const { countMissedCalls, setCountMissedCalls } = useCallContext()

    useEffect(() => {
        if (isHistoryRender) {
            setCountMissedCalls(0)
        }
    }, [isHistoryRender, setCountMissedCalls])

    return (
        <Box
            style={{
                padding: 0,
                margin: 0
            }}
        >
            <TabPanel value={isHistoryRender} index={0}>
                <RenderCalls isHistoryRender={false} />
            </TabPanel>
            <TabPanel value={isHistoryRender} index={1}>
                <CallHistory />
            </TabPanel>
            <Box style={{ position: 'absolute', bottom: 0, width: 356 }}>
                <IconLabelTabs
                    value={isHistoryRender}
                    setValue={setIsHistoryRender}
                />
                {!!countMissedCalls && (
                    <CounterMissedCalls count={countMissedCalls} />
                )}
            </Box>
        </Box>
    )
}

function IconLabelTabs({ value, setValue }) {
    const { t } = useTranslation()
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <Paper
            square
            style={{
                boxShadow: '0px -4px 5px rgba(0, 0, 0, 0.14)'
            }}
        >
            <Tabs
                style={{
                    height: 80,
                    width: '100%'
                }}
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                aria-label="icon label tabs example"
            >
                <Tab
                    icon={<RingVolume />}
                    label={t('activeCalls')}
                    style={{
                        minWidth: 0,
                        fontSize: 12
                    }}
                />
                <Tab
                    icon={<HistoryOutlined />}
                    label={t('history')}
                    style={{
                        position: 'relative',
                        minWidth: 0,
                        fontSize: 12
                    }}
                />
            </Tabs>
        </Paper>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
}

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <Paper
            square
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            style={
                {
                    // height: `calc(100vh - 120px)`,
                    // overflowY: 'auto',
                }
            }
            {...other}
        >
            {value === index && children}
        </Paper>
    )
}
