import React from 'react'
import moment from 'moment'
import 'moment/locale/uk'
import { useCallContext } from '../../reducers/Call/CallProvider'
import { useTranslation } from 'react-i18next'
import classes from './BookingModal.module.css'
import LogoOperator from '../../assets/logo-operator.svg'

const BookingModal = () => {
    const { t, i18n } = useTranslation()
    const { bookingModalInfo, setBookingModalInfo } = useCallContext()

    const closeModalHandler = () => {
        setBookingModalInfo(null)
    }

    const isCreatedMessage = bookingModalInfo?.status === 'created'

    return (
        bookingModalInfo && (
            <>
                <div
                    className={classes.overlay}
                    onClick={closeModalHandler}
                ></div>
                <div className={classes.modal}>
                    <div className={classes.modalContent}>
                        <div className={classes.header}>
                            <div className={classes.logoWrapper}>
                                <img src={LogoOperator} alt="logo" />
                                <p className={classes.logoText}>
                                    Connect Operator
                                </p>
                            </div>
                            <p className={classes.dateInfo}>
                                {moment(Date.now())
                                    .locale(i18n.language)
                                    .format('dddd HH:mm')}
                            </p>
                        </div>
                        <div className={classes.body}>
                            <h1 className={classes.title}>
                                {isCreatedMessage
                                    ? t('newBookingMeeting')
                                    : t('canceledBookingMeetings')}
                            </h1>
                            <p className={classes.text}>
                                {isCreatedMessage
                                    ? t('youHaveBooking')
                                    : t('userCancelMeeting')}
                            </p>
                            <p className={classes.text}>
                                {isCreatedMessage
                                    ? t('meetingStartAt')
                                    : t('meetingHadToStartAt')}

                                <span className={classes.date}>
                                    {moment(bookingModalInfo.startedAt)
                                        .locale(i18n.language)
                                        .format('DD MMMM YYYY, HH:mm')}
                                </span>
                            </p>
                            {bookingModalInfo.comment && (
                                <div className={classes.comment}>
                                    <p className={classes.text}>
                                        {t('commentForMeeting')}
                                        <span className={classes.commentText}>
                                            {bookingModalInfo.comment}
                                        </span>
                                    </p>
                                </div>
                            )}
                            <button
                                className={classes.btn}
                                onClick={closeModalHandler}
                            >
                                {t('iThinkIt')}
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    )
}

export default BookingModal
