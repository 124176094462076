import { URI } from '../constants/defaultConstants'
import { RequestService } from './RequestService'

export const CallService = {
    async answerCall(sessionName) {
        // sessionId =
        // 	'1_MX40NzE4Njk3NH5-MTYyOTM1NjA0MDU5MX5mUm96RTBmckE1ZzMwNGZNbW5walJFWUd-UH4';
        try {
            const response = await RequestService({
                data: { session_id: sessionName }
                // headers: {
                // 	'X-Interpreter-Token': AuthService.getUser().auth_token,
                // },
            }).postRequest(URI.answerCall)
            console.log('response', response)
            return response
        } catch (error) {
            console.error('ANSWER CALL ERR : ', error)
            throw error
        }
    },
    async getContacts(page) {
        try {
            const response = await RequestService({}).getRequest(
                `${URI.getContacts}?page=${page}`
            )
            console.log('Your Contacts is', response)
            return response
        } catch (e) {
            console.log(e)
        }
    },
    async getCallHistory() {
        try {
            const response = await RequestService({}).getRequest(
                `${URI.callHist}`
            )

            return response
        } catch (e) {
            console.log(e)
        }
    },

    async getContactData(value) {
        try {
            const response = await RequestService({}).getRequest(
                `${URI.findContact}/${value}/edit`
            )
            return response
        } catch (e) {
            console.log(e)
        }
    },
    async getIncomingCalls(value) {
        try {
            const response = await RequestService({}).getRequest(
                URI.getIncomingCalls
            )
            console.log(
                '🚀 ~ file: CallService.js ~ line 73 ~ getIncomingCalls ~ response',
                response
            )
            return response
        } catch (e) {
            console.log(e)
        }
    },
    async saveChatHistory(data) {
        try {
            const request = await RequestService({}).instance.patch(
                `/v2/call/${data.sessionName}/history`,
                data.chat
            )
            console.log('answ', request)
        } catch (e) {
            console.log(e)
        }
    }
}
