import { languageType } from "../types"

import RuIcon from 'src/assets/ru-icon.svg'
import UaIcon from 'src/assets/ua-icon.svg'
import EnIcon from 'src/assets/en-icon.svg'
import ArIcon from 'src/assets/ar-icon.svg'
import PkIcon from 'src/assets/pk-icon.svg'
import InIcon from 'src/assets/in-icon.svg'
import IslIcon from 'src/assets/isl-icon.svg'
import UsaIcon from 'src/assets/usa-flag.svg'


export const getCountryFlag = (country: languageType) => {
    switch (country) {
        case 'ua':
            return UaIcon
        case 'ru':
            return RuIcon
        case 'en':
            return UsaIcon
        case 'ar':
            return ArIcon
        case 'in':
            return InIcon
        case 'pk':
            return PkIcon
        case 'isl':
            return IslIcon
        case 'bsl':
            return EnIcon
        default:
            return null
    }
}
