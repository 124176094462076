import { useEffect, useRef, useState } from 'react'
import { concatString, formatTime } from './utils'

const CountdownTimer = ({ countdownTime }) => {
    const ref = useRef(null)
    const [time, setTime] = useState(Math.ceil(countdownTime))
    const { minutes, seconds } = formatTime(time)

    const handleStartTimer = () => {
        if (!ref.current) {
            ref.current = setInterval(() => {
                setTime((prev) => (prev > 0 ? prev - 1 : 0))
            }, 1000)
        }
    }

    const handleStopTimer = () => {
        if (ref.current) {
            clearInterval(ref.current)
            ref.current = null
        }
    }

    useEffect(() => {
        handleStartTimer()

        return () => {
            handleStopTimer()
        }
    }, [])


    return <>{concatString(minutes, seconds)}</>
}

export default CountdownTimer
