export enum CallType {
    clientToOperator = 'clientToOperator',
    employeeToOperator = 'employeeToOperator',
    clientToOperatorThroughLaptopQrCode = 'clientToOperatorThroughLaptopQrCode',
    clientToGroupCall = 'clientToGroup',
    laptopToGroupCall = 'laptopToGroup',
    laptopToOperatorCall = 'laptopToOperator',
    operatorToClient = 'operatorToClient',
    widgetToOperator = 'widgetToOperator',
    clientToOperatorCall = 'clientToOperatorCall'
}
