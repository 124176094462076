import { Box, Typography } from '@material-ui/core'
import React from 'react'

export const MessageItem = ({ message }) => {
    const { from } = message
    // console.log('message', message)
    const corners =
        from === 'Operator' ? '10px 0px 10px 10px' : '0px 10px 10px 10px'

    return (
        <Box
            style={{
                maxWidth: '80%',
                color: from === 'Operator' ? '#000' : '#fff',
                backgroundColor: from === 'Operator' ? '#fff' : '#2196F3',
                alignSelf: 'flex-end',
                borderRadius: corners,
                margin: 10,
                padding: 5,
                boxShadow:
                    '0px 3px 5px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14)',
                alignItems: 'center'
            }}
        >
            {from !== 'Operator' && (
                <Typography variant="body2">{message.from}</Typography>
            )}
            <Typography variant="body2">{message.data}</Typography>
        </Box>
    )
}
