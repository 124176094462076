import React, { useMemo, useState } from 'react'
import { createContext, useContext, useEffect, useReducer } from 'react'
import { CallActionTypes } from './CallActionsTypes'
import io from 'socket.io-client'
import { AuthService } from '../../services/AuthService'
import { AuthContext } from '../AuthReducer'
import useSound from 'use-sound'
import callSound from '../../assets/call.mp3'
import { InterpreterStatus } from 'src/types'

const initialState = {
    selectedCall: {
        type: '',
        sessionName: ''
    },
    incomingCalls: [],
    historyCalls: [],
    selectedHistoryCall: {},
    contacts: [],
    selectedContact: {},
    othersCalls: [],
    questioner: {
        status: '',
        userId: ''
    },
    operatorStatus: 'online',
    operators: [],
    operatorToken: ''
}

const reducer = (state, action) => {
    switch (action.type) {
        case CallActionTypes.PREVIEW_CALL: {
            if (state.selectedCall?.sessionName?.length === 0) {
                return {
                    ...state,
                    selectedCall: action.payload
                }
            } else {
                return state
            }
        }
        case CallActionTypes.TAKE_CALL: {
            const newIncoming = state.incomingCalls.filter(
                (el) => el.sessionName !== state.selectedCall.sessionName
            )

            return {
                ...state,
                selectedCall: { ...state.selectedCall, status: 'answered' },
                incomingCalls: newIncoming
            }
        }
        case CallActionTypes.TAKE_CALL_FROM_INCOMINGS: {
            const newIncoming = state.incomingCalls.filter(
                (el) => el.sessionName !== action.payload.sessionName
            )

            return {
                ...state,
                selectedCall: { ...action.payload, status: 'answered' },
                incomingCalls: newIncoming
            }
        }
        case CallActionTypes.SET_OPERATOR_TOKEN: {
            return {
                ...state,
                operatorToken: action.payload
            }
        }
        case CallActionTypes.DELETE_CALL: {
            console.log('DELETE CALL ')
            const newIncoming = state.incomingCalls.filter(
                (el) => el.sessionName !== action.payload
            )

            return {
                ...state,
                incomingCalls: newIncoming,
                selectedCall:
                    state.selectedCall.sessionName === action.payload
                        ? initialState.selectedCall
                        : state.selectedCall,
                operatorToken:
                    state.selectedCall.sessionName === action.payload
                        ? initialState.operatorToken
                        : state.operatorToken
            }
        }
        case CallActionTypes.END_CALL: {
            console.log('END CALL ', state.selectedCall)
            const newIncoming = state.incomingCalls.filter(
                (el) => el.sessionName !== state.selectedCall.sessionName
            )
            return {
                ...state,
                incomingCalls: newIncoming,
                selectedCall: initialState.selectedCall,
                operatorToken: ''
            }
        }
        case CallActionTypes.SET_INCOMING_CALLS: {
            return {
                ...state,
                incomingCalls: [...state.incomingCalls, action.payload]
            }
        }
        case CallActionTypes.SET_OTHERS_CALLS: {
            const newIncoming = state.incomingCalls.filter(
                (el) => el.sessionName !== action.payload
            )
            const newOthers = state.incomingCalls.find(
                (el) => el.sessionName === action.payload
            )

            return {
                ...state,
                othersCalls: newOthers
                    ? [
                          ...state.othersCalls,
                          { ...newOthers, status: 'reserved' }
                      ]
                    : state.othersCalls,
                incomingCalls: newIncoming,
                selectedCall:
                    state.selectedCall.sessionName === action.payload &&
                    state.selectedCall.status !== 'answered'
                        ? initialState.selectedCall
                        : state.selectedCall
            }
        }
        case CallActionTypes.GET_CONTACTS: {
            return {
                ...state,
                contacts: [...action.payload.items, ...state.contacts]
            }
        }
        case CallActionTypes.FIND_CONTACT: {
            return {
                ...state,
                contacts: [action.payload]
            }
        }
        case CallActionTypes.CLEAR_CONTACTS: {
            return {
                ...state,
                contacts: initialState.contacts
            }
        }
        case CallActionTypes.GET_CALL_HISTORY: {
            return {
                ...state,
                historyCalls: [...state.historyCalls, ...action.payload]
            }
        }
        case CallActionTypes.CLEAR_CALL_HISTORY: {
            return {
                ...state,
                historyCalls: []
            }
        }
        case CallActionTypes.SET_SELECTED_CONTACT: {
            return { ...state, selectedContact: { ...action.payload } }
        }
        case CallActionTypes.SET_QUESTIONER: {
            return { ...state, questioner: action.payload }
        }
        case CallActionTypes.REMOVE_QUESTIONER: {
            return { ...state, questioner: { userId: '', status: '' } }
        }
        case CallActionTypes.SET_SELECTED_HISTORY: {
            return { ...state, selectedHistoryCall: action.payload }
        }
        case CallActionTypes.SET_OPERATOR_STATUS: {
            return { ...state, operatorStatus: action.payload }
        }
        case CallActionTypes.SET_OUTGOING_CALL: {
            return {
                ...state,
                selectedCall: action.payload
            }
        }
        case 'ADD_NEW_OPERATOR': {
            let newOperators

            if (state.operators.find((el) => el.id === action.payload.id)) {
                newOperators = state.operators.map((el) =>
                    el.id === action.payload.id ? action.payload : el
                )
            } else {
                newOperators = [...state.operators, action.payload]
            }

            return { ...state, operators: newOperators }
        }
        case 'CHANGE_OPERATOR_STATUS': {
            return {
                ...state,
                operators: state.operators.map((el) =>
                    el.id === action.payload.userId
                        ? { ...el, activity: action.payload.status }
                        : el
                )
            }
        }
        default:
            return state
    }
}

export const CallContext = createContext(initialState)
export const useCallContext = () => useContext(CallContext)

export const CallProvider = ({ children }) => {
    const [callState, dispatchCall] = useReducer(reducer, initialState)
    const [isVolumeOn, setIsVolumeOn] = useState(true)
    const [isCalling, setIsCalling] = useState(false)
    const [isInStream, setIsInStream] = useState(false)
    const [bookingModalInfo, setBookingModalInfo] = useState(null)
    const [rightTabId, setRightTabId] = useState(false) //use
    const [countMissedCalls, setCountMissedCalls] = useState(0)

    const [streamAvailable, setStreamAvailable] = useState(false) //use
    const [handUpCount, setHandUpCount] = useState(0)
    const [isOperatorMuted, setIsOperatorMuted] = useState(false) //use
    const [isCameraOff, setIsCameraOff] = useState(false) //use
    const [state, dispatch] = useContext(AuthContext)
    const [playSound, exposedData] = useSound(callSound, {
        interrupt: true,
        volume: 1,
        id: 1,
        loop: true
    })

    useEffect(() => {
        if (
            callState?.selectedCall?.sessionName?.length > 0 &&
            callState?.selectedCall?.status !== 'answered' &&
            isVolumeOn &&
            isCalling &&
            callState.operatorStatus === InterpreterStatus.ONLINE
        ) {
            playSound()
        } else {
            exposedData.stop()
        }
    }, [
        callState?.selectedCall,
        isVolumeOn,
        isCalling,
        playSound,
        exposedData,
        callState.operatorStatus
    ])

    const callSocket = useMemo(() => {
        if (!state.authenticated()) return
        return io(`${process.env.REACT_APP_SOCKET_URL}/call`, {
            path: '/v2',
            extraHeaders: {
                'x-interpreter-app-type': 'connectoperator',
                authorization: `Bearer ${AuthService?.getUser()?.token}`
            }
        })
    }, [state.authenticated()])

    const operatorSocket = useMemo(() => {
        if (!state.authenticated()) return
        return io(`${process.env.REACT_APP_SOCKET_URL}/operator`, {
            path: '/v2',
            extraHeaders: {
                'x-interpreter-app-type': 'connectoperator',
                authorization: `Bearer ${AuthService?.getUser()?.token}`
            }
        })
    }, [state.authenticated()])

    useEffect(() => {
        if (!callSocket || !operatorSocket) return

        callSocket.on('connect', () => {
            console.log('call socket connected', callSocket.id)
        })

        operatorSocket.on('connect', (payload) => {
            console.log('operator socket connect', operatorSocket.id)
        })
        operatorSocket.on('new:call:created', (response) => {
            console.log('new call create', response)
            const { call, user } = response

            const newIncoming = {
                sessionName: call.sessionName,
                token: call.token,
                receiverSocketId: user.socketId,
                user: user,
                startedAt: call.initiatedAt,
                reason: call.type === 'laptopToGroup' ? 'group' : call.reason,
                // groupSessionId: call.groupSessionId,
                lang: call.language,
                call: call,
                type: call.type
            }
            dispatchCall({
                type: CallActionTypes.PREVIEW_CALL,
                payload: newIncoming
            })
            dispatchCall({
                type: CallActionTypes.SET_INCOMING_CALLS,
                payload: newIncoming
            })
            setIsCalling(true)
        })

        operatorSocket.on('call:redirected', (response) => {
            console.log('call:redirected', response)
            const { call, user } = response

            const newIncoming = {
                sessionName: call.sessionName,
                receiverSocketId: user.socketId,
                user: user,
                startedAt: call.initiatedAt,
                reason: call.type === 'laptopToGroup' ? 'group' : call.reason,
                //groupSessionId: call.groupSessionId, // add if
                lang: call.language,
                call: call,
                type: call.type
            }

            dispatchCall({
                type: CallActionTypes.PREVIEW_CALL,
                payload: newIncoming
            })
            dispatchCall({
                type: CallActionTypes.SET_INCOMING_CALLS,
                payload: newIncoming
            })
            setIsCalling(true)
        })

        operatorSocket.on('operator:activities', (payload) => {
            console.log('operator activities ', payload)
        })
        operatorSocket.on('interpreter:activity:changed', (payload) => {
            console.log('operatorSocket1 activity ', payload)
            dispatchCall({ type: 'CHANGE_OPERATOR_STATUS', payload })
        })

        operatorSocket.on('call:booked', (payload) => {
            console.log('booking', payload)
            if (payload.status !== 'created') return
            setBookingModalInfo(payload)
        })
        //answer
        operatorSocket.on('call:reserved', (response) => {
            console.log('call reserved', response)

            const replier = response?.listeners?.find(
                (el) => el.type === 'replier'
            )

            if (
                response?.call?.status === 'ended' ||
                response?.call?.status === 'missed'
            ) {
                dispatchCall({
                    type: CallActionTypes.DELETE_CALL,
                    payload: response.call.sessionName
                })
                setIsCalling(false)
                if (response.call.lockedAt) {
                    setIsInStream(false)
                }
                if (response.call.status === 'missed') {
                    setCountMissedCalls((prev) => prev + 1)
                }
            }

            if (
                AuthService?.getUser().email !== replier?.properties?.name &&
                response.sessionName
            ) {
                dispatchCall({
                    type: CallActionTypes.DELETE_CALL,
                    payload: response.sessionName
                })
                setIsCalling(false)
            }
        })

        operatorSocket.on('interpreters:statuses', (response) => {
            console.log('statuses', response)
            response.forEach((el) => {
                dispatchCall({ type: 'ADD_NEW_OPERATOR', payload: el })
            })
        })

        callSocket.on('call:handle:disconnect', (payload) => {
            const { sessionName } = payload
            dispatchCall({
                type: CallActionTypes.DELETE_CALL,
                payload: sessionName
            })
            console.log('call:handle:disconnect', payload)
        })

        callSocket.on('disconnect', () => {
            console.log('call socket disconnect')
        })

        operatorSocket.on('disconnect', () => {
            console.log('operator socket disconnect')
        })

        callSocket.on('exception', (payload) => {
            console.log('CALL SOCKET ERROR: ', payload)

            if (payload.code === 401) {
                localStorage.removeItem(`${process.env.REACT_ENV_TYPE}_user`)
                window.location.reload()
            }
        })

        operatorSocket.on('exception', (payload) => {
            console.log('OPERATOR SOCKET ERROR: ', payload)

            if (payload.code === 401) {
                localStorage.removeItem(`${process.env.REACT_ENV_TYPE}_user`)
                window.location.reload()
            }
        })

        operatorSocket.on('call:reservation:token', (payload) => {
            console.log('Operator gets token ', payload)
            dispatchCall({ type: CallActionTypes.SET_OPERATOR_TOKEN, payload })
        })

        return () => {
            callSocket.disconnect()
            operatorSocket.disconnect()
        }
    }, [callSocket, operatorSocket])

    // const switchOperator = async (socketId) => {
    //     await sessionRef.current.sessionHelper.session.signal({
    //         data: '',
    //         type: 'operatorCanRedirect'
    //     })

    //     const redirected = {
    //         type: 'redirected',
    //         sessionName: sessionRef?.current?.props?.sessionName,
    //         disconnectedId: AuthService?.getUser()?.id,
    //         socketId,
    //         connectionId: publisher?.session?.connection?.connectionId
    //     }

    //     console.log(redirected)
    //     callSocket.emit('call:handle:disconnect', redirected, (res) => {
    //         console.log('disconnected', res)
    //         dispatchCall({
    //             type: CallActionTypes.END_CALL
    //         })
    //     })
    // }

    const reserveCall = () => {
        const { call, type, user } = callState.selectedCall

        const reservationObj = { call, type, user }

        callSocket.emit('call:reservation', reservationObj, (payload) => {
            console.log('reservation ', payload)

            setIsCalling(false)
            dispatchCall({ type: CallActionTypes.TAKE_CALL })
            setIsInStream(true)
        })
    }

    const activityChange = (activity) => {
        operatorSocket.emit('activity:status', activity, (payload) => {
            console.log('activity change', payload)
            dispatchCall({
                type: CallActionTypes.SET_OPERATOR_STATUS,
                payload: payload.status
            })
        })
    }

    const endCall = () => {
        setStreamAvailable(false)
        setHandUpCount(0)
        setIsCalling(false)
        setIsInStream(false)
        setIsVolumeOn(true)

        dispatchCall({
            type: CallActionTypes.END_CALL
        })
    }

    const handleAccept = () => {
        reserveCall()
        setIsCalling(false)
    }

    const forceDisconnect = (sessionName) => {
        const userId = AuthService?.getUser()?.id
        const disconnectObj = {
            type: 'ended',
            sessionName,
            disconnectedId: userId,
            connectionId: ''
        }

        callSocket.emit('call:handle:disconnect', disconnectObj, (payload) => {
            console.log('forceDisconnect  ', payload)
            endCall()
        })
    }

    const makeOutGoingCall = (outgoingCallPayload) => {
        setIsVolumeOn(false)
        dispatchCall({
            type: CallActionTypes.SET_OUTGOING_CALL,
            payload: outgoingCallPayload
        })
    }

    return (
        <CallContext.Provider
            value={{
                callState,
                dispatchCall,
                reserveCall,
                isVolumeOn, //use
                setIsVolumeOn, //use
                bookingModalInfo, //use
                setBookingModalInfo, //use
                activityChange, //use
                // switchOperator,
                rightTabId,
                setRightTabId,
                streamAvailable, //use
                setStreamAvailable, //use
                handUpCount,
                setHandUpCount,
                handleAccept,
                setIsCalling,
                endCall, //using
                isOperatorMuted, //zoom
                setIsOperatorMuted, //zoom
                isCameraOff, //zoom
                setIsCameraOff, //zoom
                forceDisconnect, //zoom
                callSocket, //use
                isInStream, //use
                setIsInStream, //zoom
                countMissedCalls, //use
                setCountMissedCalls, //use
                makeOutGoingCall //use
            }}
        >
            {children}
        </CallContext.Provider>
    )
}
